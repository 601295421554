<template>
  <div>
    <b-card
      title="Merchants NOT Imported"
      class="mb-2"
    >
      <b-row>
        <b-col>
          <label>Merchant</label>
          <v-select
            v-model="notImportedFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="notImported"
            label="mname"
            class="w-100"
            :reduce="val => val.ps_mid"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-button
            variant="primary"
            :disabled="!notImportedFilter || notImportedFilter === ''"
            @click="importMerchant"
          >
            Import Merchant
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Merchants Imported"
      class="mb-2"
    >
      <b-row>
        <b-col>
          <label>Merchant</label>
          <v-select
            v-model="importedFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="imported"
            label="mname"
            class="w-100"
            :reduce="val => val.ps_mid"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-button
            variant="primary"
            :disabled="!importedFilter || importedFilter === ''"
            @click="importMerchantData"
          >
            Import Merchant Data
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            :disabled="!importedFilter || importedFilter === ''"
            @click="reImportMerchantApps"
          >
            Reimport Merchant APPs
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      class="mb-2"
    >
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            @click="syncAppKeys"
          >
            Sync APP Keys
          </b-button>
          <b-button
            class="ml-1"
            variant="primary"
            @click="syncUsers"
          >
            Sync Users
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Merchants Data Import Status"
      class="mb-0"
    >
      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="dataList"
        primary-key="id"
        show-empty
        striped
        sticky-header
        :borderless="false"
        outlined
        empty-text="No matching records found"
      >

        <!-- Column: Merchant -->
        <template #cell(merchant)="data">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-primary">
              {{ data.item.merchant_name }}
            </div>
            <small class="text-muted">@{{ data.item.merchant_no }}</small>
          </b-media>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BButton, BMedia,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useDevelopmentJs from './development'

const {
  fetchMerchantsFilter,
  importV1Merchant,
  importMerchantData,
  reimportMerchantApps,
  syncAppKeys,
  syncUsers,
} = useDevelopmentJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BMedia,
    vSelect,
  },
  data() {
    return {
      dataList: [],
      importedFilter: '',
      imported: [],
      notImportedFilter: '',
      notImported: [],
      tableFields: [
        'merchant',
        'status',
      ],
    }
  },
  created() {
    fetchMerchantsFilter(
      data => {
        this.dataList = data.dataList
        this.imported = data.imported
        this.notImported = data.notImported
      },
      fail => {
        showToast(this, 'Warning', `Fetch data with ${fail}`)
      },
    )
  },
  methods: {
    importMerchant() {
      importV1Merchant(
        this.notImportedFilter,
        () => {
          showToast(this, 'Success', 'Imported')
        },
        fail => {
          showToast(this, 'Warning', `Fetch data with ${fail}`)
        },
      )
    },
    importMerchantData() {
      importMerchantData(
        this.importedFilter,
        () => {
          showToast(this, 'Success', 'Imported')
        },
        fail => {
          showToast(this, 'Warning', `Fetch data with ${fail}`)
        },
      )
    },
    reImportMerchantApps() {
      reimportMerchantApps(
        this.importedFilter,
        () => {
          showToast(this, 'Success', 'Imported')
        },
        fail => {
          showToast(this, 'Warning', `Fetch data with ${fail}`)
        },
      )
    },
    syncAppKeys() {
      syncAppKeys(
        () => {
          showToast(this, 'Success', 'Sync Completed')
        },
        fail => {
          showToast(this, 'Warning', `Fetch data with ${fail}`)
        },
      )
    },
    syncUsers() {
      syncUsers(
        () => {
          showToast(this, 'Success', 'Sync Completed')
        },
        fail => {
          showToast(this, 'Warning', `Fetch data with ${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
